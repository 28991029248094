var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"pl-8 pr-8 ml-3 mr-3 xs-pl-3 notification-container"},[_c('v-row',[_c('v-col',{staticClass:"mt-3 pl-0 pr-0",attrs:{"cols":"12"}},[_c('h1',{staticClass:"notificacion__title mt-10"},[_vm._v("Notificaciones")]),_c('div',{staticClass:"container-greeting"},[_c('div',{staticClass:"mt-3"},[_c('p',{staticClass:"greeting"},[_c('v-btn',{staticClass:"mt-0 mb-2",attrs:{"x-small":"","fab":"","icon":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('img',{staticClass:"mt-1",attrs:{"width":"15","src":require("../../assets/icon/navigate_before.png")}})]),_vm._v(" "+_vm._s(_vm.name)+", estas son ")],1),_c('p',{staticClass:"greeting-requests"},[_vm._v("Sus notificaciones")])])])]),_c('v-col',{staticStyle:{"margin":"auto"},attrs:{"cols":"11","sm":"11","md":"6"}},[_c('v-row',{staticClass:"notifications-list",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-left notify-list px-0",attrs:{"cols":"12"}},[(_vm.isNotify)?_vm._l((_vm.notifications),function(notification){return _c('div',{key:notification.id},[(notification.data.length !== 0)?[_c('h2',{staticClass:"pt-6 pb-6 text-capitalize notificacion__subtitle"},[_vm._v(" "+_vm._s(notification.name)+" ")]),_vm._l((notification.data),function(not){return _c('ul',{key:not.id,staticClass:"pl-0 list"},[_c('li',[_c('v-card',{staticClass:"notifications__card_border mt-2 justify-space-between d-flex",class:not.view == 1 ? 'card' : 'pending-to-view-card ',attrs:{"elevation":"0"}},[_c('div',{staticClass:"container-notification-text"},[_c('v-card-title',{staticClass:"pt-5 pb-0",class:not.view == 1
                                ? 'notificacion__txt'
                                : 'notificacion__txt pending-to-view-text'},[_vm._v(" N° de notificación: "+_vm._s(not.case_number)+" ")]),_c('v-card-text',{staticClass:"notificacion__txt_ligth pb-5 pt-0"},[_vm._v(" "+_vm._s(not.message)+" ")])],1),_c('div',{staticClass:"d-flex justify-center align-center mr-5 notify-arrow"},[_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){not.redirect &&
                                _vm.redirectNotification(
                                  not.redirect,
                                  not.case_number
                                )}}},[_c('img',{attrs:{"src":not.view == 0
                                  ? _vm.origin + '/icon/arrow-circle-right.svg'
                                  : _vm.origin +
                                    '/icon/arrow-circle-right-grey.svg'}})])],1)])],1)])})]:_vm._e()],2)}):[_c('v-card',[_c('v-alert',{attrs:{"dense":"","text":"","type":"info"}},[_vm._v(" No hay notificaciones disponibles ")])],1)]],2)],1)],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.loadingAll}},[_c('v-progress-circular',{attrs:{"size":120,"color":"#3957B9","indeterminate":""}},[_vm._v(" Cargando... ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }