<template>
  <div>
    <div class="pl-8 pr-8 ml-3 mr-3 xs-pl-3 notification-container">
      <v-row>
        <v-col class="mt-3 pl-0 pr-0" cols="12">
          <h1 class="notificacion__title mt-10">Notificaciones</h1>
          <div class="container-greeting">
            <div class="mt-3">
              <p class="greeting">
                <v-btn
                  @click="$router.go(-1)"
                  x-small
                  class="mt-0 mb-2"
                  fab
                  icon
                >
                  <img
                    width="15"
                    class="mt-1"
                    src="../../assets/icon/navigate_before.png"
                  />
                </v-btn>
                {{ name }}, estas son
              </p>
              <p class="greeting-requests">Sus notificaciones</p>
            </div>
          </div>
        </v-col>
        <v-col cols="11" sm="11" md="6" style="margin: auto">
          <v-row justify="center" class="notifications-list">
            <v-col cols="12" class="text-left notify-list px-0">
              <template v-if="isNotify">
                <div
                  v-for="notification in notifications"
                  :key="notification.id"
                >
                  <template v-if="notification.data.length !== 0">
                    <h2
                      class="pt-6 pb-6 text-capitalize notificacion__subtitle"
                    >
                      {{ notification.name }}
                    </h2>
                    <ul
                      v-for="not in notification.data"
                      :key="not.id"
                      class="pl-0 list"
                    >
                      <li>
                        <v-card
                          elevation="0"
                          class="notifications__card_border mt-2 justify-space-between d-flex"
                          :class="
                            not.view == 1 ? 'card' : 'pending-to-view-card '
                          "
                        >
                          <div class="container-notification-text">
                            <v-card-title
                              class="pt-5 pb-0"
                              :class="
                                not.view == 1
                                  ? 'notificacion__txt'
                                  : 'notificacion__txt pending-to-view-text'
                              "
                            >
                              N° de notificación: {{ not.case_number }}
                            </v-card-title>
                            <v-card-text
                              class="notificacion__txt_ligth pb-5 pt-0"
                            >
                              {{ not.message }}
                            </v-card-text>
                          </div>
                          <div
                            class="d-flex justify-center align-center mr-5 notify-arrow"
                          >
                            <v-btn
                              small
                              icon
                              @click="
                                not.redirect &&
                                  redirectNotification(
                                    not.redirect,
                                    not.case_number
                                  )
                              "
                            >
                              <img
                                :src="
                                  not.view == 0
                                    ? origin + '/icon/arrow-circle-right.svg'
                                    : origin +
                                      '/icon/arrow-circle-right-grey.svg'
                                "
                              />
                            </v-btn>
                          </div>
                        </v-card>
                      </li>
                    </ul>
                  </template>
                </div>
              </template>
              <template v-else>
                <v-card>
                  <v-alert dense text type="info">
                    No hay notificaciones disponibles
                  </v-alert>
                </v-card>
              </template>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <v-overlay :value="loadingAll">
      <v-progress-circular :size="120" color="#3957B9" indeterminate>
        Cargando...
      </v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import Api from "@/utils/api";
import moment from "moment";
export default {
  data() {
    return {
      origin: window.location.origin,
      loadingAll: false,
      token: null,
      token64: null,
      user_id: null,
      name: "",
      infoToken: {
        document: "",
      },
      notifications: [
        {
          id: 1,
          name: "Hoy",
          data: [],
        },
        {
          id: 2,
          name: "Ayer",
          data: [],
        },
        {
          id: 3,
          name: "Anteriores",
          data: [],
        },
      ],
      notificationsFlag: null,
      isNotify: false,
      flag: true,
    };
  },
  computed: {
    user() {
      let user = this.$store.getters.getUser;
      if (user) {
        this.flag = false;
        this.getListNotify(user);
      }
    },
  },
  methods: {
    getListNotify(info) {
      this.name = info.name;
      this.loadingAll = true;
      Api.noAuth()
        .getListNotify(info.document_number)
        .then((resp) => resp.json())
        .then((data) => {
          let today = moment().format("YYYY-MM-DD");
          let yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");
          data.data.forEach((element) => {
            let date = moment(element.created_at).format("YYYY-MM-DD");
            if (date === today) {
              this.notifications[0].data.push(element);
            }
            if (date === yesterday) {
              this.notifications[1].data.push(element);
            }
            if (date !== today && date !== yesterday) {
              this.notifications[2].data.push(element);
            }
          });
          let todayNotifications = this.notifications[0].data.length;
          let yesterdayNotifications = this.notifications[1].data.length;
          let previousNotifications = this.notifications[2].data.length;
          if (
            todayNotifications !== 0 ||
            yesterdayNotifications !== 0 ||
            previousNotifications !== 0
          ) {
            this.isNotify = true;
          }
          this.loadingAll = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    redirectNotification(name, case_number) {
      let config = {
        headers: {
          Authorization: "Bearer " + this.token64,
        },
      };
      let data = {
        notification_id: case_number,
      };
      var app = this;
      Api.Auth()
        .updateNotification(data, config)
        .then(() => {
          app.$store.dispatch("updateCountNotifications");
        })
        .catch((error) => {
          console.log(error);
        });
      if (this.$route.name !== name) {
        this.$router.push({ name });
      }
    },
    getToken() {
      let token = localStorage.getItem("token");
      this.tokenB64 = localStorage.getItem("tokenB64");
      let div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        let nameList = this.infoToken.fullName.split(" ");
        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = this.toTitleCase(nameList[0]);
          } else {
            this.name = this.toTitleCase(nameList[1]);
          }
        } else {
          this.name = "";
        }
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    getProfile() {
      Api.noAuth()
        .profile(this.infoToken.document)
        .then((res) => {
          if (this.flag) {
            this.getListNotify(res.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toTitleCase(str) {
      return str
        .toLowerCase()
        .split(" ")
        .map(function (word) {
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ");
    },
  },
  created() {
    this.token = localStorage.getItem("token");
    this.token64 = localStorage.getItem("tokenB64");
  },
  mounted() {
    this.getToken();
    this.getProfile();
  },
  watch: {
    user: function (newCount, oldCount) {
      // console.log(`Las notificaciones son ${newCount}`);
      console.log("");
    },
  },
};
</script>
<style scoped>
@import "./../../assets/css/main.less";

.notificacion__title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
  text-align: initial;
}

.notificacion__subtitle {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #353535;
}

.notificacion__txt {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #353535;
}

.notificacion__txt_ligth {
  font-family: "RobotoLight";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  color: #353535;
}

.notifications__card_border {
  border-radius: 15px !important;
  border: 0.5px solid #d5dbff;
  border-radius: 15px;
}

.list {
  list-style: none;
}

.notification-list-title {
  font-family: "proximaNovaRegular";
  font-weight: 400;
}

.notifications-name {
  font-family: "AsapRegular";
  font-weight: 600;
  font-size: 16px;
  color: #626262;
  padding-bottom: 0;
  width: 90%;
}

.pending-to-view {
  color: #466be3;
}

/* .card {
  border-left: 3px solid transparent;
} */

.pending-to-view-card {
  border: 1px solid #466be3 !important;
}

.pending-to-view-text {
  color: #466be3;
}

.notifications-text {
  font-family: "ProximaNovaLight";
  font-weight: 400;
  font-size: 16px;
  color: #353535;
}

.container-notification-text {
  width: 90%;
}

.container-greeting {
  display: none;
}

.greeting {
  color: #466be3;
  font-family: "proximaNovaRegular";
  margin: 0 !important;
  font-weight: 300;
  font-size: 25px;
  line-height: 30px;
  text-align: left;
}

.greeting-requests {
  font-family: "AsapSemiBold";
  font-weight: 600;
  margin: 0 !important;
  font-size: 16px;
  line-height: 18px;
  text-align: left;
  padding-left: 40px;
}

.notificacion__title {
  display: block;
}

/* LAPTOP 4k */
@media (max-width: 2000px) {
}

/* LAPTOP LARGE */
@media (max-width: 1600px) {
}

/* LAPTOP LARGE */
@media (max-width: 1400px) {
}

/* LAPTOP NORMAL */
@media (max-width: 1200px) {
}

/* TABLET */
@media (max-width: 1023px) {
  .notification-banner {
    display: none;
  }

  .container-greeting {
    display: flex;
    /* justify-content: center; */
  }

  .notifications-list {
    padding-bottom: 80px;
  }

  .notificacion__title {
    display: none;
  }
}

/* MOBILE L*/
@media (max-width: 767px) {
  .notify-list {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .container-greeting {
    justify-content: normal;
  }

  .notification-container {
    margin: 0 !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
}

/* MOBILE M */
@media (max-width: 385px) {
  .notifications-name {
    width: 100%;
    padding-right: 0;
    padding-left: 5px;
  }

  .notifications-text {
    padding-left: 5px;
    padding-right: 0;
  }

  .notify-arrow {
    margin-right: 0 !important;
  }
}

/* MOBILE S */
@media (max-width: 320px) {
}
</style>
